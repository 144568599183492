import { Link } from 'gatsby'
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  StaticImage,
} from 'gatsby-plugin-image'
import React, { useMemo, useState } from 'react'
import { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { reparationsLinks } from '../data/menu.context'
import Form from './Form'
import capitalizeFirstLetter from '../utils/capitalizeFirstLetter'

interface CardContainerProps {
  index: number
}

const CardContainer = styled.div<CardContainerProps>`
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: translateZ(0); // fix ios Display

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  }

  &::before {
    content: '';
    width: 55px;
    height: 55px;
    position: absolute;
    z-index: 1;
    top: -16px;
    left: -16px;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 500px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 146px;
      height: 146px;
      top: -48px;
      left: -48px;
    }
  }

  &::after {
    content: '${({ index }) => index}';
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #ffffff;
    position: absolute;
    z-index: 2;
    top: 6px;

    ${({ index }) => {
      return index === 1
        ? css`
            left: 12px;
          `
        : css`
            left: 9px;
          `
    }}

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 71px;
      line-height: 85px;

      ${({ index }) => {
        return index === 1
          ? css`
              left: 26px;
            `
          : css`
              left: 18px;
            `
      }}
    }
  }

  & > div {
    position: relative;
    z-index: 0;

    & > *[class^='RepairContent__Search'] {
      margin: 0 20px 20px 20px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin: 30px auto 20px auto;
        max-width: min(750px, calc(100% - 2 * 20px));
      }
    }

    & > *[class^='RepairContent__GridList'] {
      padding-bottom: 21px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-bottom: 36px;
      }
    }

    & > *[class^='RepairContent__Cgu'] {
      padding-bottom: 60px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-bottom: 180px;
      }
    }
  }
`

const title = css`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 20px;
    line-height: 24px;
  }
`

interface TitleBaseProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const TitleBase: React.FC<TitleBaseProps> = ({ as, ...rest }) => {
  return React.createElement(as ?? 'h2', rest)
}

interface CardTitleProps {
  hideTitleOnDesktop?: boolean
  floatingTitleOnDesktop?: boolean
}

const Title = styled(TitleBase)<CardTitleProps>`
  ${title}
  padding: 12px 0px;
  margin: 0 40px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 41px 0px 30px 0px;
    margin: 0 100px;

    ${({ hideTitleOnDesktop }) => {
      return (
        hideTitleOnDesktop &&
        css`
          display: none;
        `
      )
    }}

    ${({ floatingTitleOnDesktop }) => {
      return (
        floatingTitleOnDesktop &&
        css`
          position: absolute;
          z-index: 401;
          top: 0;
          left: 0;
          right: 0;
        `
      )
    }}
  }
`

interface SeoHeaderContainerProps {
  floatingOnDesktop?: boolean
}

const SeoHeaderContainer = styled.div<SeoHeaderContainerProps>`
  padding: 0px 30px 0px 30px;
  margin: 10px 10px 20px 10px;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
  gap: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 10px 90px 10px 90px;
    gap: 10px;
  }

  ${({ floatingOnDesktop }) => {
    return (
      floatingOnDesktop &&
      css`
        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          z-index: 401;
          box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
          border-radius: 10px;
          background: #fff;
        }
      `
    )
  }}
`

const SeoSubTitle = styled.strong`
  ${title}
`

const SeoTitle = styled.h1`
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 18px;
    line-height: 20px;
  }
`

interface SeoHeaderProps {
  title: string
  subtitle: string
  floatingOnDesktop?: boolean
}

const SeoHeader: React.FC<SeoHeaderProps> = ({
  subtitle,
  title,
  floatingOnDesktop,
}) => (
  <SeoHeaderContainer floatingOnDesktop={floatingOnDesktop}>
    <SeoTitle>{title}</SeoTitle>
    <SeoSubTitle>{subtitle}</SeoSubTitle>
  </SeoHeaderContainer>
)

interface CardProps {
  index: number
  title?: React.ReactNode
  children?: React.ReactNode
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ children, index, title }, ref) => (
    <CardContainer ref={ref} index={index}>
      <div>
        {title}
        {children}
      </div>
    </CardContainer>
  )
)

export const cardMaxWith = 848

export const cardSizing = css`
  max-width: min(${cardMaxWith}px, calc(100% - 2 * 10px));
  margin: 0 auto;
`

const RepairContent = styled.div`
  background-color: #fff;
  flex: 1;
  padding-bottom: 130px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: 160px;
  }

  & > *[class^='RepairContent__CardContainer'] {
    ${cardSizing}
  }

  & > *[class^='Highlights'] {
    margin-bottom: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-bottom: 20px;
    }
  }

  & > *[class^='RepairContent__Cgu'] {
    max-width: calc(850px + 2 * 16px);
    margin: 0 auto;
    padding: 0px 16px 0px 16px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 0px 16px 0px 16px;
    }
  }

  & > .RepairShopSelect {
    ${cardSizing}
    margin-top: 40px;
  }
`

const Cgu = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};

  & > h3 {
    text-transform: uppercase;
  }

  & > p {
    margin-bottom: 14px;
  }
`

const LinkWrapper = styled(Link)`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  padding: 1px 10px 6px 10px;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 1px 10px 10px 10px;
  }

  & > span:first-child {
    width: 104px;
    height: 104px;
    margin: 10px 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 156px;
      height: 156px;
    }

    & > * {
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: contain !important;
    }
  }

  & > h2,
  & > span:last-child {
    font-style: normal;
    font-weight: 350;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export type GridItem = {
  name?: string | null
  imageFile?: {
    publicURL?: string | null
    childImageSharp?: {
      gatsbyImageData?: IGatsbyImageData | null
    } | null
  } | null
}

interface CardLinkProps<Item extends GridItem> {
  item: Item
  pathBuilder: (item: Item) => string
  type?:string
}

const CardLink = <Item extends GridItem>({
  item,
  pathBuilder,
  type
}: CardLinkProps<Item>) => {
  const image = useMemo(() => {
    const imageData = item.imageFile?.childImageSharp?.gatsbyImageData
    if (imageData) {
      return getImage(imageData)
    }
    return undefined
  }, [])

  return (
    <LinkWrapper to={pathBuilder(item)} data-ga4-event={ type == "brands" ? "select-brand" : "select-model"} data-ga4-param-value={item.name}>
      <span>
        {image ? (
          <GatsbyImage image={image} alt={item.name ?? ''} />
        ) : item.imageFile?.publicURL ? (
          <img src={item.imageFile?.publicURL ?? ''} />
        ) : (
          <StaticImage
            src="../images/SmartphoneDefault.png"
            alt={item.name ?? ''}
            quality={100}
            placeholder="blurred"
            width={170}
            height={170}
          />
        )}
      </span>
      <h2>{capitalizeFirstLetter(item.name)}</h2>
    </LinkWrapper>
  )
}

const More = styled.span`
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 86px;
  line-height: 103px;
  align-items: center;
  color: #000000;
  display: flex;
  justify-content: center;

  & > * {
    width: auto !important;
    height: auto !important;
  }
`

const CardLinkOther = () => (
  <LinkWrapper to={reparationsLinks.other}>
    <More>
      <span>+</span>
    </More>
    <span>Autres</span>
  </LinkWrapper>
)

const GridList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(124px, 124px));
  justify-content: center;
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: repeat(auto-fit, minmax(176px, 176px));
    column-gap: 15px;
    row-gap: 16px;
  }
`

const Search = styled(Form.Input)``

interface GridProps<Item extends GridItem> {
  items: Item[]
  pathBuilder: (item: Item) => string
  withSearch?: string
  type?:string
}

const Grid = <Item extends GridItem>({
  items,
  pathBuilder,
  withSearch,
  type
}: GridProps<Item>) => {
  const [search, setSearch] = useState('')

  return (
    <>
      {withSearch && (
        <Search
          placeholder={withSearch}
          onChange={(e) => setSearch(e.target.value)}
          type="search"
        />
      )}
      <GridList>
        {items
          .filter((x) =>
            x.name
              ?.toLocaleLowerCase()
              .includes(search.trim().toLocaleLowerCase())
          )
          .map((item) => (
            <li key={item.name}>
              <CardLink item={item} pathBuilder={pathBuilder} type={type} />
            </li>
          ))}
        <li>
          <CardLinkOther />
        </li>
      </GridList>
    </>
  )
}

export default Object.assign(RepairContent, {
  Card,
  Grid,
  Cgu,
  Title,
  SeoHeader,
})
